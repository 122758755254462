<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("samples") }}</h1>
      <button v-if="role == 100" @click="open = true" class="bg-green-500 px-3 rounded text-gray-200">
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <div>
      <el-table :data="samples" border v-loading="loading" style="width: 100%" class="rounded-lg shadow-md">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="px-3 flex flex-col">
              <div v-for="(instruction, index) in props.row.instruction" :key="instruction.id"
                class="text-base py-4 w-max">
                <h2 class="font-medium">
                  {{ $t("instruction") + " " + (index + 1) }}
                </h2>
                <div class="text-sm flex items-center">
                  <p class="font-medium w-20">{{ $t("name") }} :</p>
                  {{ instruction.name }}
                </div>
                <div class="text-sm flex items-center">
                  <p class="font-medium w-20">{{ $t("file") }} :</p>
                  <a class="text-blue-500 flex items-center" :href="instruction.file">
                    <icon class="text-red-500 mr-1" icon="file" style="width: 12px; height: 12px"></icon>
                    {{ instruction.file.split("/").pop() }}
                  </a>
                </div>

                <div class="text-sm flex items-center">
                  <p class="font-medium w-20">{{ $t("place") }} :</p>
                  <p v-for="place in props.row.place" :key="place.id">{{ place.name }}&nbsp;</p>
                </div>
              </div>
              <div class="flex-1">
                <p class="text-lg font-medium">{{ $t("tasks") }}</p>
                <ul class="ml-4">
                  <li class="list-disc" v-for="(task, index) in props.row.tasks" :key="index">
                    {{ task.name }}
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('name')" prop="name" min-width="300">
        </el-table-column>
        <el-table-column :label="$t('type')" prop="type_name" width="120">
        </el-table-column>
        <el-table-column :label="$t('corrective_type')" prop="corrective_type.name" width="180">
        </el-table-column>
        <el-table-column prop="created_time" :label="$t('created_time')" min-width="180">
        </el-table-column>
        <el-table-column prop="last_updated_time" :label="$t('last_updated_time')" min-width="240">
        </el-table-column>
        <el-table-column :label="$t('actions')" fixed="right" :min-width="actionsWidth" v-if="role == 100">
          <template slot-scope="scope">
            <el-button size="mini" @click="previewSample(scope.row.id)" type="warning">
              <i class="el-icon-view"></i>
              <span class="hidden lg:inline-block">{{ $t("preview") }}</span>
            </el-button>

            <el-button size="mini" @click="openEdit(scope.row.id)">
              <i class="el-icon-edit"></i>
              <span class="hidden lg:inline-block">{{ $t("edit") }}</span>
            </el-button>
            <el-popconfirm :confirm-button-text="$t('yes')" :cancel-button-text="$t('no_thanks')" icon="el-icon-info"
              icon-color="red" :title="$t('you_want_to_delete')" class="ml-2" @confirm="SampleDelete(scope.row.id)">
              <el-button slot="reference" size="mini" type="danger">
                <i class="el-icon-delete"></i>
                <span class="hidden lg:inline-block">{{ $t("delete") }}</span>
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-3 flex justify-end">
        <el-pagination background layout="prev, pager, next" hide-on-single-page @prev-click="Prev" @next-click="Next"
          @current-change="handleCurrentChange" :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-drawer :visible.sync="open" :with-header="false" size="400px" @close="CloseDrawer" v-if="role == 100">
      <div class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl">
        <p>EXSERVICE</p>
        <button @click="open = false" class="px-2">
          <i class="el-icon-close"></i>
        </button>
      </div>
      <div class="p-4">
        <h1 class="mb-4">{{ $t("add_samples") }}</h1>
        <el-form ref="sample" label-position="top" :model="sample">
          <el-form-item prop="name" style="margin-bottom: 10px" :label="$t('name')" :rules="{
            required: true,
            message: $t('please_input'),
            trigger: 'blur',
          }">
            <el-input :placeholder="$t('input')" v-model="sample.name" clearable>
            </el-input>
          </el-form-item>
          <el-form-item prop="place" style="margin-bottom: 10px" :label="$t('place')" :rules="{
            required: true,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-select v-model="sample.place" :placeholder="$t('select')" multiple filterable class="w-full">
              <el-option v-for="place in places" :key="place.id" :label="place.name" :value="place.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="instruction" style="margin-bottom: 10px" :label="$t('instruction')" :rules="{
            required: basicaCheck,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-select v-model="sample.instruction" :placeholder="$t('select')" multiple filterable class="w-full">
              <el-option v-for="instruction in instructions" :key="instruction.id" :label="instruction.name"
                :value="instruction.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('corrective_type')">
            <el-select v-model="sample.corrective_type" class="w-full" filterable :placeholder="$t('select')">
              <el-option v-for="corrective_type in corrective_types" :key="corrective_type.id"
                :label="corrective_type.name" :value="corrective_type.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="tasks" style="margin-bottom: 10px" :label="$t('tasks')" :rules="{
            required: true,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-select v-model="sample.tasks" class="w-full" multiple filterable :placeholder="$t('select')">
              <el-option v-for="task in filtered_tasks" :key="task.id"
                :label="task.name.length >= 100 ? task.name.slice(0, lengthChar) + ' . . . ' : task.name"
                :value="task.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="type" style="margin-bottom: 10px" :label="$t('type')" :rules="{
            required: true,
            message: $t('please_select'),
            trigger: 'blur',
          }">
            <el-select v-model="sample.type" class="w-full" filterable :placeholder="$t('select')">
              <el-option v-for="type_item in types" :key="type_item.id" :label="type_item.name" :value="type_item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-right">
          <el-button v-if="!edit" @click="addSample" type="primary" :loading="loading">
            {{ $t("add") }}
          </el-button>
          <el-button v-else @click="editSample" type="primary" :loading="loading">
            {{ $t("save") }}
          </el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="preview" :with-header="false" z-index="10000" width="90%" @close="CloseDialog">
      <h1 class="px-4 py-2 text-3xl font-bold text-111E52 text-center">{{ $t('preview') }}</h1>
      <el-radio-group class="mt-4" v-model="size">
        <el-radio label="">Default</el-radio>
        <el-radio label="medium">Medium</el-radio>
        <el-radio label="small">Small</el-radio>
        <el-radio label="mini">Mini</el-radio>
      </el-radio-group>

      <el-descriptions class="mt-4" :column="3" :size="size" border v-if="preview_sample">
        <el-descriptions-item>
          <template slot="label">
            <strong>{{ $t("name") }}</strong>
          </template>
          <p class="w-60">{{ preview_sample.name }}</p>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <strong>{{ $t("type") }}</strong>
          </template>
          {{  preview_sample.type_name  }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <strong>{{ $t("corrective_type") }}</strong>
          </template>
          {{  preview_sample.corrective_type.name  }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <strong>{{ $t("created_time") }}</strong>
          </template>
          {{  preview_sample.created_time  }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <strong>{{ $t("last_updated_time") }}</strong>
          </template>
          {{  preview_sample.last_updated_time  }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <strong>{{ $t("place") }}</strong>
          </template>
          <p v-for="place in preview_sample.place" :key="place.id">{{ place.name }}</p>
        </el-descriptions-item>
        <el-descriptions-item span="3">
          <template slot="label">
            <strong>{{ $t("instruction") }}</strong>
          </template>
          <el-link class="mr-2" v-for="instruction in preview_sample.instruction" target="_blank" :href="instruction.file" :key="instruction.id" :underline="false"><el-tag size="small"><i class="el-icon-document el-icon--left"></i> {{ instruction.name }} <i class="el-icon-top-right el-icon--right"></i></el-tag></el-link>
        </el-descriptions-item>
        <el-descriptions-item span="3">
          <template slot="label">
            <strong>{{ $t("tasks") }}</strong>
          </template>
          <el-collapse>
            <el-collapse-item v-for="(task, index) in preview_sample.tasks" :key="index" :title="task.name">
              <p class="ml-2">{{ task.description }}</p>
            </el-collapse-item>
          </el-collapse>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import icon from "../components/icon.vue";
export default {
  components: { icon },
  name: "Samples",
  data() {
    return {
      open: false,
      edit: false,
      preview: false,
      totalCount: 0,
      count: 1,
      loading: false,
      id: "",
      lengthChar: 0,
      size: '',

      sample: {
        name: "",
        place: [],
        instruction: [],
        tasks: "",
        corrective_type: "",
        type: "",
      },
      types: [
        {
          name: "PREVENTIVE",
          id: "10",
        },
        {
          name: "CORRECTIVE",
          id: "9",
        },
        {
          name: "VANDALISM",
          id: "8",
        },
      ],

      samples: [],
      edit_sample: "",
      preview_sample: "",
      places: "",
      instructions: "",
      corrective_types: "",
      TASKS: "",
      role: localStorage.role,
    };
  },
  mounted() {
    this.Selects();
    this.getSamples();
    this.lengthCharFunc(window.innerWidth);
  },
  watch: {
    '$i18n.locale': function () {
      this.getTasks();
      this.getSamples();
    },
    'sample.corrective_type': function (_, oldVal) {
      if(typeof oldVal == 'number') {
        this.sample.tasks = "";
      }
    }
  },
  computed: {
    actionsWidth() {
      if (window.innerWidth >= 992) {
        return this.$i18n.locale === 'ru' ? 400 : 330;
      } else {
        return 172;
      }
    },
    filtered_tasks() {
      return this.sample.corrective_type ? this.TASKS.filter(el => el.corrective_type.id === this.sample.corrective_type) : this.TASKS;
    },
    basicaCheck() {
      return this.sample.corrective_type ? (this.corrective_types.find((el) => el.id === this.sample.corrective_type).name === 'BASICA' ? false : true) : true;
    }
  },
  methods: {
    lengthCharFunc(width) {
      if (width >= 1500) {
        this.lengthChar = 170;
      } else if (width >= 1300 && width < 1500) {
        this.lengthChar = 150;
      } else if (width >= 1000 && width < 1300) {
        this.lengthChar = 120;
      } else if (width >= 800 && width < 1000) {
        this.lengthChar = 100;
      } else if (width >= 600 && width < 800) {
        this.lengthChar = 80;
      } else {
        this.lengthChar = 40;
      }
    },
    async getSamples() {
      this.loading = true;
      if (this.$i18n.locale === 'uz') {
        await axios
          .get(`samples-list/${this.count === 1 ? "" : "?page=" + this.count}`)
          .then((response) => {
            this.totalCount = response.data.count;
            this.samples = response.data.results.map((data) => {
              return {
                ...data,
                created_time: data.created_time
                  .split(".")
                  .shift()
                  .replace("T", " "),
                last_updated_time: data.last_updated_time
                  .split(".")
                  .shift()
                  .replace("T", " "),
                type_name:
                  data.type === "10"
                    ? "PREVENTIVE"
                    : data.type === "9"
                      ? "CORRECTIVE"
                      : "VANDALISM",
              };
            });
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        const storageToken = localStorage.getItem("token");
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/ru/api/samples-list/${this.count === 1 ? "" : "?page=" + this.count}`, {
          method: 'GET',
          headers: {
            'ngrok-skip-browser-warning': "any",
            'Authorization': storageToken ? `Token ${storageToken}` : "",
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          this.loading = false;
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();

        this.totalCount = data.count;
        this.samples = data.results.map((data) => {
          return {
            ...data,
            created_time: data.created_time
              .split(".")
              .shift()
              .replace("T", " "),
            last_updated_time: data.last_updated_time
              .split(".")
              .shift()
              .replace("T", " "),
            type_name:
              data.type === "10"
                ? "PREVENTIVE"
                : data.type === "9"
                  ? "CORRECTIVE"
                  : "VANDALISM",
          };
        });
        this.loading = false;
      }
    },
    Prev() {
      this.count--;
      this.getSamples();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getSamples();
    },
    Next() {
      this.count++;
      this.getSamples();
    },
    async Selects() {
      await axios.get("places/?no_page").then((response) => {
        this.places = response.data;
      });
      await axios.get("instructions/?no_page").then((response) => {
        this.instructions = response.data;
      });
      await axios.get("corrective-types/?no_page").then((response) => {
        this.corrective_types = response.data;
      });
      this.getTasks();
    },
    async getTasks() {
      if (this.$i18n.locale === 'uz') {
        await axios.get("tasks-list/").then((response) => {
          this.TASKS = response.data;
        });
      } else {
        const storageToken = localStorage.getItem("token");
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/ru/api/tasks-list/`, {
          method: 'GET',
          headers: {
            'ngrok-skip-browser-warning': "any",
            'Authorization': storageToken ? `Token ${storageToken}` : "",
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        this.TASKS = data;
      }
    },
    CloseDrawer() {
      this.edit = false;
      Object.keys(this.sample).forEach((key) => {
        if (Array.isArray(this.sample[key])) {
          this.sample[key] = [];
        } else {
          this.sample[key] = "";
        }
      });
      this.$refs.sample.clearValidate();
    },

    CloseDialog() {
      this.preview = false;
    },

    addSample() {
      this.$refs.sample.validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.sample);
          Object.keys(data).forEach((key) => {
            if (data[key] === "") {
              delete data[key];
            }
          });
          this.loading = true;
          axios
            .post("samples/", data)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getSamples();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.id = id;
      this.edit_sample = this.samples.find((data) => data.id === id);
      Object.keys(this.sample).forEach((key) => {
        if (key === "tasks") {
          this.sample[key] = this.edit_sample[key].map(({ id }) => id);
        } else if (
          typeof this.edit_sample[key] === "object" &&
          this.edit_sample[key]
        ) {
          if (Array.isArray(this.edit_sample[key])) {
            this.edit_sample[key].forEach(el => this.sample[key].push(el.id)); // sample.instruction should be [1, 2, 3]
          } else {
            this.sample[key] = this.edit_sample[key].id;
          }

        } else {
          this.sample[key] = this.edit_sample[key];
        }
      });
    },
    previewSample(id) {
      this.preview = true;
      this.id = id;
      this.preview_sample = this.samples.find((data) => data.id === id);
    },
    editSample() {
      this.$refs.sample.validate((valid) => {
        if (valid) {
          this.loading = true;
          axios
            .patch(`samples/${this.id}/`, this.sample)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getSamples();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
    async SampleDelete(id) {
      this.loading = true;
      await axios
        .delete(`samples/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getSamples();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
